import React from 'react'

import cn from 'classnames/bind'
import {useRouter} from 'next/router'

import ErrorPageContent from 'components/blocks/ErrorPageContent'
import Button from 'components/elements/Button'
import {Container, Row, Col} from 'components/elements/grid'
import {TPageComponent} from 'src/types'

import s from './Template404.module.scss'
const cx = cn.bind(s)

interface ITemplateProps {
	className?: string
}

const Template404: TPageComponent<ITemplateProps> = ({className}) => {
	const router = useRouter()

	const GoToHomeBtn = () => {
		return (
			<Button size="big" onClick={() => router.push('/')}>
				Go to home
			</Button>
		)
	}

	return (
		<div className={cx('wrapper', className)}>
			<Container>
				<div className={cx('wrapper-inner')}>
					<Row>
						<Col md={{span: 10}} lg={{span: 8}} xl={{offset: 1, span: 8}}>
							<ErrorPageContent
								title="Error 404"
								subtitle="Page not found"
								description="This page doesn’t exist or was removed"
								button={<GoToHomeBtn />}
								className={cx('error-content')}
							/>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	)
}

Template404.displayName = 'Template404'

export default Template404
