import {useEffect} from 'react'

import LayoutHome from 'components/layouts/LayoutHome'
import Template404 from 'components/templates/Template404'
import {posthogTrack} from 'src/utils'

const NotFound = () => {
	useEffect(() => {
		posthogTrack('Page 404 was opened')
	}, [])

	return <Template404 />
}

NotFound.Layout = LayoutHome

export default NotFound
